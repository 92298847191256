<template>
  <div class="mine-page">
    <section class="instru-item" v-for="(item, index) in list" :key="item.id">
      <div class="instru-plan">
        <div class="instru-title">Plan:</div>
        <dl class="instru-dl">
          <dt>{{item.total}} {{item.unit}} plan</dt>
          <dd>{{item.total}} {{item.unit}}</dd>
        </dl>
        <dl class="instru-dl">
          <dt>Active subscription</dt>
          <dd>{{item.remark}} | {{item.expire}}</dd>
        </dl>
        <dl class="instru-dl">
          <dt>ProxyInfo</dt>
          <dd>{{item.proxyInfo}}</dd>
        </dl>
      </div>
      <div class="instru-traffic">
        <div class="instru-title">Traffic:</div>
        <dl class="instru-dl">
          <dt>{{item.apply}} {{item.unit}} of {{item.total}} {{item.unit}} used</dt>
          <dd>
            <el-progress :percentage="item.percentage" color="#FA4347"></el-progress>
          </dd>
        </dl>
        <ul class="instru-info">
          <li>
            <span class="instru-info-label">Upload</span>
            <span class="instru-info-value">{{item.upload}} {{item.unit}}</span>
          </li>
          <li>
            <span class="instru-info-label">Download</span>
            <span class="instru-info-value">{{item.download}} {{item.unit}}</span>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getApplyInfos } from '@/apis/mine'

export default {
  components: {},
  props: {},
  data () {
    return {
      list: [],
      // detail: {
      //   upload: null,
      //   download: null,
      //   apply: null,
      //   total: null,
      //   percentage: 0,
      //   remark: null
      // }
    }
  },
  computed: {},
  watch: {},
  methods: {
    ...mapMutations(['setLoading']),
    init() {
      this.setLoading({
        state: true,
        text: ''
      })
      getApplyInfos({}, (res) => {
        // console.log(res)
        res.forEach((item) => {
          // console.log(item)
            (item.upload + item.download) / item.total * 100.00
          const cl = (item.upload + item.download) / item.total * 100.00;
          // 保留两位小数
          const roundedPercentage = parseFloat(cl.toFixed(2));
          item.percentage = roundedPercentage;


          const apply2 = parseFloat(item.apply.toFixed(2));
          item.apply = apply2;

          const upload2 = parseFloat(item.upload.toFixed(4));
          item.upload = upload2;

          const download2 = parseFloat(item.download.toFixed(4));
          item.download = download2;

        })
        this.list = res
        // console.log(this.list)
        this.setLoading({
          state: false,
          text: ''
        })
      }, (err) => {
        // console.log(err)
        this.setLoading({
          state: false,
          text: ''
        })
      })
    }
  },
  created () {
    this.init()
  },
  mounted () {},
  destroyed () {}
}
</script>